.gradients{
    background-image: linear-gradient(to right, red, yellow);
}

.social{
    background-image: linear-gradient(to right, #7171FF, #4646A9);
}

.invenue{
    background-image: linear-gradient(to right, #7171FF, #4646A9);
}

.linear{
    background-image: linear-gradient(to right, #6EF9FF, #00FDC0);
}

.highlights{
    background-image: linear-gradient(to right, #FFCD00, #FFA44F);
}

.digital{
    background-image: linear-gradient(to right, #CCD80A, #F5FF55);
}

.sponsorship{
    background-image: linear-gradient(to right, #8CFF8E, #55FF00);

}

.wassaman{
    background-color: #F35500;
}


.nhl-digital{
    background-image: linear-gradient(to right, #f1ff0e, #f6ff6b)
}

.nhl-social{
    background-image: linear-gradient(to right, #7171FF, #4646A9);
}

.nhl-highlights{
    background-image: linear-gradient(to right, #FFCD00, #FFA44F);
}

.nhl-linear{
    background-image: linear-gradient(to right, #6EF9FF, #00FDC0);
}


/* channelToColor: {
    "Social": '#4444A5',
    "Digital": "yellow",
    "Highlights": '#FF9027',
    "Linear": '#44A1A5',
} */