table.comparisonTable td {
    padding: 1em !important; 
    font-family: sans-serif;
    font-weight: 900;
 }

 table.comparisonTable th{
     font-weight: bolder !important;
     font-size: 1.2em;
     background-color: mediumpurple !important;

 }
