.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
      cursor: pointer;
    visibility: hidden;
    width: 80px;
    background-color: rgb(197, 10, 10);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 1px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .expanded-nav .tooltip:hover .tooltiptext {
    visibility: hidden;
  }