.dbData:hover{
    cursor: pointer;
    box-shadow: 0 0 3px 3px chartreuse;
}



 .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    
  }

  .tableFixHead          { overflow-y: auto; height: 70vh; }
  .tableFixHead thead th { position: sticky; top: 0;  
    z-index: 50 !important;}
  
  /* Just common table stuff. Really. */
  table  { border-collapse: collapse; width: 100%; }
  th, td, input { padding: 8px; }
  td, input{
      padding: 0;
  }
  th     { background:black; }
  
  /* Borders (if you need them) */