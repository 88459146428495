.zoom {
    transition: transform .1s; /* Animation */
    
  }
  
  .zoom:hover {
    cursor: pointer;
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    background-color: teal;
    color: white
  
  }
  
  .zoom:hover .middle {
    opacity: 1;
  
  }
  
  .zoom:active{
  }